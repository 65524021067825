<template>
    <section class="list-logos-container">
        <div class="list-logos">
            <WatsonAtomDynamicText
                :text="title"
                :typography="typography.title"
                class="list-logos__title"
            />

            <div class="list-logos__partners">
                <div
                    v-for="item in items"
                    class="list-logos__partner"
                >
                    <WatsonAtomImage
                        v-if="item.logo"
                        :content="item.logo"
                        class="list-logos__partner-logo"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import WatsonAtomDynamicText from '@atoms/dynamic-text/watson-atom-dynamic-text.vue';
import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';

const props = defineProps({
    content: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    title: {
        type: String,
        required: false,
        default: '',
    },
    items: {
        type: Array,
        required: false,
        default: () => [],
    },
    partnerStyles: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    style: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    typography: {
        type: Object,
        required: false,
        default: () => ({}),
    },
});
</script>

<style lang="scss">
@use 'watson-theme-list-logos';
</style>

<style lang="scss" scoped>
@layer theme {
    .list-logos {
        --background: v-bind('props.style.background');
        --title-color: v-bind('props.style.titleColor');
    }
}
</style>
